import React from "react"
import { Row, Col, Container, ListGroup } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Jumbotron from "../components/jumbotron"
import Articles from "../components/articles"
import Carousel from "../components/carousel"

const Resources = () => (
	<Layout pageInfo={{ pageName: "index" }}>
		<SEO title="Resources" keywords={[`gatsby`, `react`, `bootstrap`]} />

		<Jumbotron pageInfo={{title: "Resources", subtitle: "Information and Resources About Q-Net Security and Our Products", backgroundImage: '/images/resources.jpg' }} />

		<section>
			<Container>
				<Row>
					<Col xs="12" sm="6" md="7">
						<h2>Articles</h2>
						<Articles />
					</Col>
					<Col>
						<h2><i class="fad fw-fw fa-file-pdf"></i> Downloads</h2>
						<ListGroup>
							<ListGroup.Item>
								<a target="_blank" href={'/documents/QNS - Overview.pdf'}>
									QNS - Overview <i class="far fa-external-link-alt"></i>
								</a>
							</ListGroup.Item>
							<ListGroup.Item>
								<a target="_blank" href={'/documents/QNS - Network Whitepaper.pdf'}>
									QNS - Network Whitepaper <i class="far fa-external-link-alt"></i>
								</a>
							</ListGroup.Item>
							<ListGroup.Item>
								<a target="_blank" href={'/documents/QNS - Use Case - ATMs.pdf'}>
									QNS - Use Case - ATMs <i class="far fa-external-link-alt"></i>
								</a>
							</ListGroup.Item>
							<ListGroup.Item>
								<a target="_blank" href={'/documents/QNS - Utility Introduction.pdf'}>
									QNS - Utility Introduction <i class="far fa-external-link-alt"></i>
								</a>
							</ListGroup.Item>
							<ListGroup.Item>
								<a target="_blank" href={'/documents/QNS - Financial Industry Introduction.pdf'}>
									QNS - Financial Industry Introduction <i class="far fa-external-link-alt"></i>
								</a>
							</ListGroup.Item>
							<ListGroup.Item>
								<a target="_blank" href={'/documents/QNS - Financial Industry Network Whitepaper.pdf'}>
									QNS - Financial Industry Network Whitepaper <i class="far fa-external-link-alt"></i>
								</a>
							</ListGroup.Item>
							<ListGroup.Item>
								<a target="_blank" href={'/documents/QNS - Financial Industry Writeup.pdf'}>
									QNS - Financial Industry Writeup <i class="far fa-external-link-alt"></i>
								</a>
							</ListGroup.Item>
							<ListGroup.Item>
								<a target="_blank" href={'/documents/Magtek ExpressCard 2000 + Q-Net Security.pdf'}>
									Magtek ExpressCard 2000 + Q-Net Security <i class="far fa-external-link-alt"></i>
								</a>
							</ListGroup.Item>
							<ListGroup.Item>
								<a target="_blank" href={'/documents/Why Hardware - Whitepaper.pdf'}>
									Why Hardware - Whitepaper <i class="far fa-external-link-alt"></i>
								</a>
							</ListGroup.Item>
						</ListGroup>
					</Col>
				</Row>
			</Container>
		</section>
	</Layout>
)

export default Resources
