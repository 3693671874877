import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col, Container, ListGroup } from "react-bootstrap"

export default () => (
	<StaticQuery
		query={graphql`
      query ArticleListing {
		allMarkdownRemark(
		filter: { fileAbsolutePath: {regex : "\\/articles/"} },
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 20
        ) {
			edges {
			  node {
				id
				frontmatter {
				  authorImg
				  authorName
				  authorTitle
				  backgroundImage
				  date
				  path
				  subtitle
				  title
				  excerpt
				}
			  }
			}
		  }
		}
    `}
		render={(data) => {

			let links = data.allMarkdownRemark.edges.map((article) =>
				<Row className={'article'}>
					<Col>
						<h4 className={'article-title'}>
							<a href={article.node.frontmatter.path}>
								{article.node.frontmatter.title}
							</a>
						</h4>
						<p><small>{article.node.frontmatter.excerpt}</small></p>
						<a className={'btn button'} href={article.node.frontmatter.path}>Read More</a>

					</Col>
				</Row>
			)

			if( links.length == 0 ){
				return (
					<Row className={'article'}>
						<Col>
							<h4 className={'article-title'}>
								<small className="text-muted">
									Thank you for your interest. Please check back later.
								</small>
							</h4>
						</Col>
					</Row>
				)
			}

			return (
				<div className="article-listing">
					{links}
				</div>
			)
		}
	}
	/>
)